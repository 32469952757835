export class BraidAch {
    accountNumber: string;
    bankAccountType: 'SAVINGS' | 'CHECKING';
    bankName: string;
    routingNumber: string;
}

export class BraidAuthentication {
    username: string;
    api_key: string;
}

export const QUESTIONNAIRES_ALLOWED_FOR_BRAID = [
    'KYC Individual',
    'KYC Corporate',
    'Name of owners with 10% or more ownership ( Please provide multiple answers if there is nested ownership )'
];

export const QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO = [
    'Name of owners with 10% or more ownership ( Please provide multiple answers if there is nested ownership )'
];
