import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {DEFAULT_CURRENCY_CODE, NgModule, Optional, SkipSelf} from '@angular/core';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';
import {SettlementsState} from '@app/admin-module/states/settlements.state';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {ApiService} from '@app/core-module/services/api.service';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {CountriesService} from '@app/core-module/services/countries.service';
import {DebugService} from '@app/core-module/services/debug.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {ScanningService} from '@app/core-module/services/scanning.service';
import {AccTypesState} from '@app/core-module/states/acc-types.state';
import {CurrentBankState} from '@app/core-module/states/current.bank.state';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {IdTypesState} from '@app/core-module/states/id-types.state';
import {OrgTypesState} from '@app/core-module/states/org-types.state';
import {UserTypesState} from '@app/core-module/states/user-types.state';
import {AccountsState} from '@app/fi-module/states/accounts.state';
import {BalancesState} from '@app/fi-module/states/balances.state';
import {DefaultAccountsState} from '@app/fi-module/states/default.accounts.state';
import {PersonsState} from '@app/fi-module/states/persons.state';
import {OrderByPipe} from 'ngx-pipes';
import {TransactionStatusesState} from '@app/core-module/states/transaction-statuses.state';
import {TransactionTypesState} from '@app/core-module/states/transaction-types.state';
import {LanguageService} from '@app/core-module/services/language.service';
import {RdcStatusCodesState} from "@app/core-module/states/rdc-status-codes.state";
import {WireTransferFormStatusTypesState} from "@app/core-module/states/wire-transfer-form-status-types.state";
import {TableFooterService} from "@app/core-module/services/table-footer.service";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    AuthenticationService,
    ApiService,
    AdminApiService,
    AlertsService,
    DebugService,
    CountriesService,
    RxUtilsService,
    ScanningService,
    TableFooterService,
    CurrentProfileState,
    BalancesState,
    AccountsState,
    PersonsState,
    AccTypesState,
    SettlementsState,
    IdTypesState,
    UserTypesState,
    OrgTypesState,
    DefaultAccountsState,
    DatePipe,
    OrderByPipe,
    CurrencyPipe,
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'USD'},
    CurrentBankState,
    TransactionStatusesState,
    TransactionTypesState,
    RdcStatusCodesState,
    WireTransferFormStatusTypesState,
    LanguageService,
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('CoreModule should not be imported in other modules then AppModule');
    }
  }
}
