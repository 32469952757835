import {HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {map, switchMap, timeout} from 'rxjs/operators';
import {CustomerDocument} from '@shared/models/customer-document';
import {of} from 'rxjs/internal/observable/of';
import {getAccessToken} from '@app/shared-module/utils/utils';

export abstract class AbstractApiService {

  protected domain = environment.domain;

  constructor(protected http: HttpClient) {
  }

  protected getOptions(reportProgress = false) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + getAccessToken(),
      'TimezoneOffset': new Date().getTimezoneOffset() + ''
    });
    return {headers: headers, reportProgress: reportProgress};
  }

  protected getBlobOptions() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + getAccessToken(),
      'TimezoneOffset': new Date().getTimezoneOffset() + ''
    });
    return {headers: headers, responseType: 'blob' as 'blob', observe: 'response' as 'response'};
  }

  protected createGetRequest<T>(uri: string): Observable<T> {
    return of(null).pipe(
      map(() => this.getOptions()),
      switchMap(options =>
        this.http.get<T>(this.domain + uri, options)
      )
    );
  }

  protected createBlobGetRequest(uri: string): Observable<HttpResponse<Blob>> {
    return of(null).pipe(
      map(() => this.getBlobOptions()),
      switchMap(options =>
        this.http.get(this.domain + uri, options).pipe(timeout(1000 * 60 * 20))
      )
    );
  }

  protected createBlobPostRequest(uri: string, object: any): Observable<HttpResponse<Blob>> {
    return of(null).pipe(
      map(() => this.getBlobOptions()),
      switchMap(options =>
        this.http.post(this.domain + uri, object, options).pipe(timeout(1000 * 60 * 20))
      )
    );
  }

  protected createPostRequest<T>(uri: string, object = null): Observable<T> {
    return of(null).pipe(
      map(() => this.getOptions()),
      switchMap(options =>
        this.http.post<T>(this.domain + uri, object, options)
      )
    );
  }

  protected createPostRequestWithProgress<T>(uri: string, object = null): Observable<HttpEvent<any>> {
    return of(null).pipe(
      map(() => this.getOptions(true)),
      switchMap(options => {
        const req = new HttpRequest('POST', this.domain + uri, object, options);
        return this.http.request(req);
      })
    );
  }

  protected createPutRequest<T>(uri: string, object: any): Observable<T> {
    return of(null).pipe(
      map(() => this.getOptions(true)),
      switchMap(options =>
        this.http.put<T>(this.domain + uri, object, options)
      )
    );
  }

  protected createDeleteRequest<T>(uri: string): Observable<T> {
    return of(null).pipe(
      map(() => this.getOptions(true)),
      switchMap(options =>
        this.http.delete<T>(this.domain + uri, options)
      )
    );
  }

  private prepareParams(params: {}) {
    const keys = Object.keys(params);
    keys.forEach(key => {
      if (params[key] == null) {
        delete params[key];
      }
    });
  }

  protected createGetRequestWithParams<T>(uri: string, params: {}): Observable<T> {
    return of(null).pipe(
      map(() => {
        this.prepareParams(params);
        const options = this.getOptions();
        options['params'] = params;
        return options;
      }),
      switchMap(options =>
        this.http.get<T>(this.domain + uri, options)
      )
    );
  }

  protected createBlobGetRequestWithParams<T>(uri: string, params: {}): Observable<HttpResponse<Blob>> {
    return of(null).pipe(
      map(() => {
        this.prepareParams(params);
        const options = this.getBlobOptions();
        options['params'] = params;
        return options;
      }),
      switchMap(options =>
        this.http.get(this.domain + uri, options))
    );
  }

  public abstract postCustomerDocuments(profileId: number, formData: FormData): Observable<CustomerDocument[]>;

  public abstract deleteCustomerDocument(profileId: number, documentId: number): Observable<CustomerDocument>;

}
