export class ErrorResponseError extends Error {
    constructor(public errorCode: string, public status: number = 500) {
        super();
    }
}

export class IErrorResponseError {
    errorCode: string;
    status: number;
}
// @OriginalMessage Invalid Upstream Bank for Braid API
export const ERROR_INVALID_UPB_FOR_BRAID_API = 'Braid_error-invalid-upb-for-api';
// @OriginalMessage Invalid Segregated Person Profile
export const ERROR_INVALID_SEGREGATED_PERSON_PROFILE = 'Braid_error-invalid-segregated-person-profile';
// @OriginalMessage Wire Account and Segregated Account doesn't have the same 'Upstream Bank'.
export const ERROR_NOT_SAME_UPB_FOR_WIRE_ACC_AND_SEGREGATED_ACC = 'Braid_error-not-same-upb-for-wire-and-segregated-acc';
// @OriginalMessage This Legal Entity is not synced with Braid
export const ERROR_LEGAL_ENTITY_NOT_SYNCED_WITH_BRAID = 'Braid_error-legal-entity-not-synced-with-Braid';
// @OriginalMessage This Individual is not synced with Braid
export const ERROR_INDIVIDUAL_NOT_SYNCED_WITH_BRAID = 'Braid_error-individual-not-synced-with-Braid';
// @OriginalMessage Invalid Segregated Account
export const ERROR_INVALID_LOCAL_SEGREGATED_ACCOUNT = 'Braid_error-invalid-local-segregated-acc';
// @OriginalMessage Invalid Upstream Bank for Segregated Account
export const ERROR_INVALID_UPB_FOR_LOCAL_SEGREGATED_ACCOUNT = 'Braid_error-invalid-upb-for-local-segregated-acc';
// @OriginalMessage Invalid Funding Account
export const ERROR_INVALID_LOCAL_FUNDING_ACCOUNT = 'Braid_error-invalid-local-funding-account';
// @OriginalMessage Invalid Upstream Bank for Funding Account
export const ERROR_INVALID_UPB_FOR_LOCAL_FUNDING_ACCOUNT = 'Braid_error-invalid-upb-for-local-funding-account';
// @OriginalMessage Unknown KYC Profile to sync with Braid
export const ERROR_UNKNOWN_KYC_PROFILE_TO_SYNC_WITH_BRAID = 'Braid_error-unknown-kyc-profile-to-sync-with-Braid';
// @OriginalMessage Failed to create account as this Business Record was blocked by Braid
export const ERROR_BUSINESS_BLOCKED_BY_BRAID = 'Braid_error-business-blocked-by-Braid';
// @OriginalMessage Failed to create account as this Individual Record was blocked by Braid
export const ERROR_INDIVIDUAL_BLOCKED_BY_BRAID = 'Braid_error-individual-blocked-by-Braid';
// @OriginalMessage Unknown Upstream Bank
export const ERROR_UNKNOWN_UPB = 'Braid_error-unknown-upb';
// @OriginalMessage Braid is not enabled on this Upstream Bank.
export const ERROR_BRAID_NOT_ENABLED_FOR_UPB = 'Braid_error-not-enabled-for-upb';
// @OriginalMessage Unknown Person
export const ERROR_UNKNOWN_PERSON = 'Braid_error-unknown-person';
// @OriginalMessage Unknown KYC Profile
export const ERROR_UNKNOWN_KYC_PROFILE = 'Braid_error-unknown-kyc-profile';
// @OriginalMessage This Person is not of type: 'LEGAL_ENTITY'
export const ERROR_PERSON_TYPE_NOT_LEGAL_ENTITY = 'Braid_error-person-type-not-legal-entity';
// @OriginalMessage This Person is not of type: 'INDIVIDUAL'
export const ERROR_PERSON_TYPE_NOT_INDIVIDUAL = 'Braid_error-person-type-not-individual';
// @OriginalMessage Invalid ID Type
export const ERROR_INVALID_ID_TYPE = 'Braid_error-invalid-id-type';
// @OriginalMessage Invalid Organization Type
export const ERROR_INVALID_ORGANIZATION_TYPE = 'Braid_error-invalid-organization-type';
// @OriginalMessage Error while creating Business Record in Braid
export const ERROR_CREATING_BUSINESS_IN_BRAID = 'Braid_error-creating-business';
// @OriginalMessage Invalid Business Record in Braid
export const ERROR_INVALID_BUSINESS_IN_BRAID = 'Braid_error-invalid-business';
// @OriginalMessage Error while updating Business Record in Braid.
export const ERROR_UPDATING_BUSINESS_IN_BRAID = 'Braid_error-updating-business';
// @OriginalMessage Error while creating Individual Record in Braid
export const ERROR_CREATING_INDIVIDUAL_IN_BRAID = 'Braid_error-creating-individual';
// @OriginalMessage Invalid Individual Record in Braid
export const ERROR_INVALID_INDIVIDUAL_IN_BRAID = 'Braid_error-invalid-individual';
// @OriginalMessage Error while updating Individual Record in Braid.
export const ERROR_UPDATING_INDIVIDUAL_IN_BRAID = 'Braid_error-updating-individual';
// @OriginalMessage Unknown Account
export const ERROR_UNKNOWN_LOCAL_ACC = 'Braid_error-unknown-local-account';
// @OriginalMessage Unknown Braid Account Number
export const ERROR_UNKNOWN_BRAID_ACC_NUMBER = 'Braid_error-unknown-account-number';
// @OriginalMessage Unknown Segregated Account
export const ERROR_UNKNOWN_LOCAL_SEGREGATED_ACC = 'Braid_error-unknown-local-segregated-account';
// @OriginalMessage Invalid Sender Account
export const ERROR_INVALID_LOCAL_SENDER_ACC = 'Braid_error-invalid-local-sender-acc';
// @OriginalMessage Invalid Upstream Bank for Sender Account
export const ERROR_INVALID_UPB_FOR_LOCAL_SENDER_ACC = 'Braid_error-invalid-upb-for-local-sender-acc';
// @OriginalMessage Invalid Receiver Account
export const ERROR_INVALID_LOCAL_RECEIVER_ACC = 'Braid_error-invalid-local-receiver-acc';
// @OriginalMessage Invalid Upstream Bank for Receiver Account
export const ERROR_INVALID_UPB_FOR_LOCAL_RECEIVER_ACC = 'Braid_error-invalid-upb-for-local-receiver-acc';
// @OriginalMessage Not allowed to synchronize IONFI Accounts with Braid that already have transactions
export const ERROR_NOT_ALLOWED_LOCAL_ACC_SYNC_WITH_BRAID = 'Braid_error-sync-local-acc';
// @OriginalMessage Segregated Accounts must have 0 Current Balance
export const ERROR_LOCAL_SEGREGATED_ACC_MUST_HAVE_0_CURRENT_BALANCE = 'Braid_error-local-segregated-acc-must-have-0-current-balance';
// @OriginalMessage Invalid Wire Transfer Form
export const ERROR_INVALID_WFORM = 'Braid_error-invalid-wform';
// @OriginalMessage Invalid Originator Person
export const ERROR_INVALID_ORIGINATOR_PERSON = 'Braid_error-invalid-originator-person';
// @OriginalMessage Invalid Beneficiary Person
export const ERROR_INVALID_BENEFICIARY_PERSON = 'Braid_error-invalid-beneficiary-person';
// @OriginalMessage Braid is enabled for this person but this Wire Transfer Form is not segregated
export const ERROR_BRAID_ENABLED_BUT_WFORM_NOT_SEGREGATED = 'Braid_error-wform-not-segregated';
// @OriginalMessage This segregated account was not registered in Braid
export const ERROR_LOCAL_SEGREGATED_ACC_NOT_SYNCED_WITH_BRAID = 'Braid_error-local-segregated-acc-not-synced';
// @OriginalMessage Invalid Beneficiary Bank
export const ERROR_INVALID_BENEFICIARY_BANK = 'Braid_error-invalid-beneficiary-bank';
// @OriginalMessage Invalid Intermediary Bank
export const ERROR_INVALID_INTERMEDIARY_BANK = 'Braid_error-invalid-intermediary-bank';
// @OriginalMessage Can't create transaction in Braid when Beneficiary and Intermediary Bank have the same Bank Number Type
export const ERROR_CANT_CREATE_TRAN_IN_BRAID_WITH_BANKS_WITH_SAME_NUM_TYPE = 'Braid_error-cant-create-tran-with-banks-with-same-num-type';
// @OriginalMessage Invalid Wire Account
export const ERROR_INVALID_WIRE_LOCAL_ACC = 'Braid_error-invalid-wire-local-acc';
// @OriginalMessage This Wire Account was not registered in Braid
export const ERROR_LOCAL_WIRE_ACC_NOT_REGISTERED_IN_BRAID = 'Braid_error-local-wire-acc-not-registered';
// @OriginalMessage Invalid 'domestic_amount' value
export const ERROR_INVALID_DOMESTIC_AMOUNT_VALUE = 'Braid_error-invalid-domestic-amount-value';
// @OriginalMessage Failed to create/update Counterparty in Braid
export const ERROR_CREATING_UPDATING_COUNTERPARTY_IN_BRAID = 'Braid_error-creating-updating-counterparty';
// @OriginalMessage Unsupported way to create transaction in Braid
export const ERROR_UNSUPPORTED_WAY_TO_CREATE_TRAN_IN_BRAID = 'Braid_error-unsupported-way-to-create-transaction';
// @OriginalMessage This segregated account is not registered in Braid
export const ERROR_LOCAL_SEGREGATED_ACC_NOT_REGISTERED_IN_BRAID = 'Braid_error-local-segregated-acc-not-registered';
// @OriginalMessage Intermediary Bank is Required when Beneficiary Bank does not have 'ABA Routing Number'
export const ERROR_WFORM_INTERNATIONAL_INTER_BANK_FOR_NON_ABA_ROUTING_NUM_BENEFICIARY = 'error-mandatory-intermediary-bank-when-benef-bank-num-is-non-aba-routing-num';
// @OriginalMessage Beneficiary Bank ID Type should be non 'ABA Routing Number'
export const ERROR_WFORM_INTERNATIONAL_BENEF_BANK_ID_NUM_SHOULDNT_BE_ABA = 'error-mandatory-intermediary-benef-bank-num-should-be-aba';
// @OriginalMessage Beneficiary Bank must be with 'ABA Routing Number' when Wire is 'Domestic'
export const ERROR_WFORM_DOMESTIC_BENEFICIARY_BANK_WITH_NON_ABA_ROUTING_NUM = 'error-mandatory-benef-bank-with-aba-routing-num-when-domestic-wire';
// @OriginalMessage Invalid Beneficiary Person DDA Number
export const ERROR_INVALID_BENEFICIARY_PERSON_DDA_NUMBER = 'Braid_error-invalid-beneficiary-person-dda-number';
// @OriginalMessage Counterparty in Braid is not with status 'ACTIVE'
export const ERROR_COUNTERPARTY_IN_BRAID_IS_NOT_ACTIVE = 'Braid_error-counterparty-is-not-active';
// @OriginalMessage Invalid Counterparty in Braid
export const ERROR_INVALID_COUNTERPARTY_IN_BRAID = 'Braid_error-invalid-counterparty';
// @OriginalMessage Invalid Counterparty in Braid
export const ERROR_INVALID_TENANT_ID_IN_BRAID = 'Braid_error-invalid-tenant-id';
// @OriginalMessage Invalid Username/API Key.
export const ERROR_INVALID_USERNAME_OR_API_KEY_IN_BRAID = 'Braid_error-invalid-username-or-api-key';
// @OriginalMessage Error while verifying Upstream Bank credentials
export const ERROR_WHILE_VERIFYING_UPB_CREDENTIALS = 'Braid_error-invalid-upb-credentials';
// @OriginalMessage Invalid Braid Product Data
export const ERROR_INVALID_BRAID_PRODUCT_DATA = 'Braid_error-invalid-product-data';
// @OriginalMessage Invalid Product in Braid
export const ERROR_INVALID_BRAID_PRODUCT = 'Braid_error-invalid-product';
// @OriginalMessage Invalid Upstream Bank Configuration
export const ERROR_INVALID_UPB_CONFIG = 'Braid_error-invalid-upb-config';
// @OriginalMessage Server returned invalid Webhooks
export const ERROR_INVALID_BRAID_WEBHOOKS_RESPONSE = 'Braid_error-invalid-webhooks-response';
// @OriginalMessage Insufficient funds on the Sender Account
export const ERROR_BRAID_INSUFFICIENT_FUNDS_ON_SENDER_ACCOUNT = 'Braid_error-insufficient-funds-on-sender-acc';
// @OriginalMessage alerts.response-errors: Either {5000} or {5010} is required when {3600} is 'CTP' or 'CTR'
export const ERROR_FEDWIRE_FILE_5000_OR_5010_REQUIRED = 'fedwire_file-5000-or-5010-required';
// @OriginalMessage At least one Bank must be with 'ABA Routing Number' Bank Number type
export const ERROR_WFORM_INTERNATIONAL_NO_BANK_WITH_ABA_ROUTING_NUMBER = 'WForm_error-international-no-bank-with-aba-routing-num';
// @OriginalMessage Failed to create UBO(s) in Braid
export const ERROR_BRAID_CREATE_UBO = 'Braid_error-failed-to-create-ubo-to-business';
// @OriginalMessage Failed to update UBO(s) in Braid
export const ERROR_BRAID_UPDATE_UBO = 'Braid_error-failed-to-update-ubo-to-business';
// @OriginalMessage Failed to detach UBO(s) in Braid
export const ERROR_BRAID_DELETE_UBO = 'Braid_error-failed-to-delete-ubo-to-business';
// @OriginalMessage Not allowed to merge profiles that are synced with Braid
export const ERROR_MERGE_KYC_PROFILES_SYNCED_WITH_BRAID = 'Braid_error-kyc-profile-merge';
// @OriginalMessage Not allowed to merge profiles that have created segregated accounts
export const ERROR_MERGE_KYC_PROFILES_WITH_SEGREGATED_ACCOUNTS = 'Error-merge-kyc-profiles-with-segregated-accounts';
// @OriginalMessage Failed to create autofilled Segregated Account for the Originator Person, please create one manually
export const ERROR_CREATING_AUTOFILLED_ORIGINATOR_PERSON_SEGREGATED_ACCOUNT = 'Error-creating-autofilled-originator-person-segregated-account';
// @OriginalMessage Failed to create autofilled Segregated Account for the Ultimate Beneficiary Person, please create one manually
export const ERROR_CREATING_AUTOFILLED_ULTIMATE_BENEFICIARY_PERSON_SEGREGATED_ACCOUNT = 'Error-creating-autofilled-ultimate-beneficiary-person-segregated-account';
