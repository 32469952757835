import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {
  AuthenticationComponent
} from '@app/shared-module/components/group-general/authentication/authentication.component';
import {
  InputsCountryComponent
} from '@app/shared-module/components/group-general/inputs-country/inputs-country.component';
import {LoadingBarComponent} from '@app/shared-module/components/group-general/loading-bar/loading-bar.component';
import {
  PersonDetailsComponent
} from '@app/shared-module/components/group-general/person-details/person-details.component';
import {ScannerInfoComponent} from '@app/shared-module/components/group-general/scanner-info/scanner-info.component';
import {
  ScannerInfoCardComponent
} from '@app/shared-module/components/group-general/scanner-info-card/scanner-info.component';
import {SidebarUserComponent} from '@app/shared-module/components/group-general/sidebar-user/sidebar-user.component';
import {UserProfileComponent} from '@app/shared-module/components/group-general/user-profile/user-profile.component';
import {
  AddPersonModalComponent
} from '@app/shared-module/components/group-modals/add-person-modal/add-person-modal.component';
import {
  AddUserModalComponent
} from '@app/shared-module/components/group-modals/add-user-modal/add-user-modal.component';
import {
  ConfirmDeleteComponent
} from '@app/shared-module/components/group-modals/confirm-delete/confirm-delete.component';
import {
  UpdateBankModalComponent
} from '@app/shared-module/components/group-modals/update-bank-modal/update-bank-modal.component';
import {
  UpdateUserModalComponent
} from '@app/shared-module/components/group-modals/update-user-modal/update-user-modal.component';
import {CountryPipe} from '@app/shared-module/pipes/country.pipe';
import {IdTypeCodePipe} from '@app/shared-module/pipes/id.type.code.pipe';
import {IdTypeLabelPipe} from '@app/shared-module/pipes/id.type.label.pipe';
import {OrgTypeNamePipe} from '@app/shared-module/pipes/org.type.name.pipe';
import {StatePipe} from '@app/shared-module/pipes/state.pipe';
import {UserTypePipe} from '@app/shared-module/pipes/user.type.pipe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  NgbAlertModule,
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {
  BaseChartDirective,
  provideCharts,
  withDefaultRegisterables} from 'ng2-charts';
import {NgPipesModule} from 'ngx-pipes';
import {NewLinePipe} from '@app/shared-module/pipes/new-line.pipe';
import {NgxFileDropModule} from 'ngx-file-drop';
import {NewLineRegexPipe} from '@app/shared-module/pipes/new-line-regex.pipe';
import {AnswerViewComponent} from './components/group-general/answer-view/answer-view.component';
import {
  QuestionDetailsModalComponent
} from './components/group-modals/question-details-modal/question-details-modal.component';
import {VolumeFeePipe} from './pipes/volume-fee.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {EntityNumberTypePipe} from './pipes/entity-number-type.pipe';
import {ActivityPipe} from './pipes/activity.pipe';
import {FilesUploadComponent} from '@app/shared-module/components/files-upload/files-upload.component';
import {ErrorMessagePipe} from './pipes/error-message.pipe';
import {QuestionComponent} from '@app/shared-module/components/question/question.component';
import {QuestionBlockComponent} from '@app/shared-module/components/question-block/question-block.component';
import {
  QuestionsAnsweredComponent
} from '@app/shared-module/components/questions-answered/questions-answered.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {InputFileComponent} from './components/input-file/input-file.component';
import {
  InputWireTransferEntityComponent
} from './components/input-wire-transfer-entity/input-wire-transfer-entity.component';
import {
  WireTransferEditingFormComponent
} from './components/wire-transfer-editing-form/wire-transfer-editing-form.component';
import {
  WireTransferEntityDetailsComponent
} from './components/wire-transfer-entity-details/wire-transfer-entity-details.component';
import {
  AddWireTransferBankModalComponent
} from './components/add-wire-transfer-bank-modal/add-wire-transfer-bank-modal.component';
import {TransactionsTableComponent} from './components/transactions-table/transactions-table.component';
import {
  TransactionsAccountDetailsDescriptionComponent
} from './components/transactions-account-details-description/transactions-account-details-description.component';
import {
  DisplayTransactionsModalComponent
} from './components/display-transactions-modal/display-transactions-modal.component';
import {StateTimelineComponent} from '@app/shared-module/components/state-timeline/state-timeline.component';
import {
  DisplayAndUploadDocumentsComponent
} from './components/group-general/display-and-upload-documents/display-and-upload-documents.component';
import {
  WireTransferFormsFilterComponent
} from './components/wire-transfer-forms-filter/wire-transfer-forms-filter.component';
import {WireProfileEntityTypePipe} from './pipes/wire-profile-entity-type.pipe';
import {InputCurrencyComponent} from './components/input-currency/input-currency.component';
import {CurrencyFullNamePipe} from './pipes/currency-full-name.pipe';
import {
  ProcessingRdcsTableComponent
} from '@app/shared-module/components/processing-rdcs-table/processing-rdcs-table.component';
import {ReturnFeeModalComponent} from '@app/shared-module/components/return-fee-modal/return-fee-modal.component';
import {BodyDesignComponent} from './components/body-design/body-design.component';
import {KeysPipe} from '@app/shared-module/pipes/key.pipe';
import {
  AccountsActivityTableComponent
} from '@app/shared-module/components/accounts-activity-table/accounts-activity-table.component';
import {TableComponent} from '@app/shared-module/components/table/table.component';
import {
  ProvideMoreInfoAnswerModalComponent
} from '@app/shared-module/components/group-modals/provide-more-info-answer-modal/provide-more-info-answer-modal.component';
import {
  EditQuestionAnswerModalComponent
} from './components/group-modals/edit-question-answer-modal/edit-question-answer-modal.component';
import {
  WireTransferBankDetailsComponent
} from '@app/shared-module/components/group-general/wire-transfer-bank-details/wire-transfer-bank-details.component';
import {ToggleGraphsService} from '@app/shared-module/services/toggle-graphs.service';
import {ToggleGraphsIconComponent} from '@app/shared-module/components/toggle-graphs-icon/toggle-graphs-icon.component';
import {PersonTypePipe} from '@app/shared-module/pipes/person-type.pipe';
import {EntityTypePipe} from '@app/shared-module/pipes/entity-type.pipe';
import {TableMessagesPipe} from '@app/shared-module/pipes/table-messages.pipe';
import {PersonNamePipe} from '@app/shared-module/pipes/person-name.pipe';
import {MasterPersonNamePipe} from '@app/shared-module/pipes/master-person-name.pipe';
import {
  AccountDetailsBalance
} from '@app/shared-module/components/group-general/account-details-balance/account-details-balance.component';
import {
  WireTransferEntityAddressComponent
} from "@app/shared-module/components/wire-transfer-entity-address/wire-transfer-entity-address.component";
import {
  WireTransferSupportiveDocumentationComponent
} from "@app/shared-module/components/wire-transfer-supportive-documentation/wire-transfer-supportive-documentation.component";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {NgBootstrapFormValidationModule} from '@mingleats/ng-bootstrap-form-validation';
import {MatButton} from "@angular/material/button";
import {MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {UpstreamBanksState} from "@app/admin-module/states/upstream-banks.state";
import {ConfigurationState} from '@app/admin-module/states/configuration.state';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgPipesModule,
        NgxDatatableModule,
        BaseChartDirective,
        FontAwesomeModule,
        RouterModule,
        NgbDropdownModule,
        NgbModalModule,
        NgbAlertModule,
        NgbTooltipModule,
        NgbCarouselModule,
        NgbTypeaheadModule,
        NgBootstrapFormValidationModule,
        NgxFileDropModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        NgbCollapseModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatButton,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle
    ],
  declarations: [
    SidebarUserComponent,
    AddUserModalComponent,
    LoadingBarComponent,
    UpdateUserModalComponent,
    UpdateBankModalComponent,
    AddPersonModalComponent,
    InputsCountryComponent,
    CountryPipe,
    StatePipe,
    OrgTypeNamePipe,
    IdTypeLabelPipe,
    IdTypeCodePipe,
    UserTypePipe,
    NewLinePipe,
    NewLineRegexPipe,
    TableMessagesPipe,
    PersonDetailsComponent,
    UserProfileComponent,
    ConfirmDeleteComponent,
    ScannerInfoComponent,
    ScannerInfoCardComponent,
    AuthenticationComponent,
    AnswerViewComponent,
    QuestionDetailsModalComponent,
    VolumeFeePipe,
    EntityNumberTypePipe,
    ActivityPipe,
    PersonNamePipe,
    DisplayAndUploadDocumentsComponent,
    FilesUploadComponent,
    ProvideMoreInfoAnswerModalComponent,
    ErrorMessagePipe,
    QuestionComponent,
    QuestionBlockComponent,
    QuestionsAnsweredComponent,
    InputFileComponent,
    InputWireTransferEntityComponent,
    WireTransferEditingFormComponent,
    WireTransferSupportiveDocumentationComponent,
    WireTransferEntityDetailsComponent,
    WireTransferEntityAddressComponent,
    AddWireTransferBankModalComponent,
    TransactionsTableComponent,
    TransactionsAccountDetailsDescriptionComponent,
    DisplayTransactionsModalComponent,
    StateTimelineComponent,
    WireTransferFormsFilterComponent,
    WireProfileEntityTypePipe,
    InputCurrencyComponent,
    CurrencyFullNamePipe,
    ProcessingRdcsTableComponent,
    ReturnFeeModalComponent,
    BodyDesignComponent,
    KeysPipe,
    AccountsActivityTableComponent,
    TableComponent,
    EditQuestionAnswerModalComponent,
    WireTransferBankDetailsComponent,
    ToggleGraphsIconComponent,
    PersonTypePipe,
    MasterPersonNamePipe,
    EntityTypePipe,
    AccountDetailsBalance
  ],
  providers: [
    ErrorMessagePipe,
    ToggleGraphsService,
    provideCharts(withDefaultRegisterables()),
    UpstreamBanksState,
    ConfigurationState
  ],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgxDatatableModule,
    BaseChartDirective,
    FontAwesomeModule,
    RouterModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbAlertModule,
    NgbTooltipModule,
    NgbCarouselModule,
    NgbTypeaheadModule,
    NgBootstrapFormValidationModule,
    NgxFileDropModule,
    CountryPipe,
    StatePipe,
    OrgTypeNamePipe,
    IdTypeLabelPipe,
    IdTypeCodePipe,
    UserTypePipe,
    NewLinePipe,
    NewLineRegexPipe,
    SidebarUserComponent,
    AddUserModalComponent,
    LoadingBarComponent,
    UpdateUserModalComponent,
    UpdateBankModalComponent,
    AddPersonModalComponent,
    InputsCountryComponent,
    PersonDetailsComponent,
    UserProfileComponent,
    ConfirmDeleteComponent,
    ScannerInfoComponent,
    AuthenticationComponent,
    AnswerViewComponent,
    QuestionDetailsModalComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    VolumeFeePipe,
    EntityNumberTypePipe,
    ActivityPipe,
    NgbCollapseModule,
    DisplayAndUploadDocumentsComponent,
    FilesUploadComponent,
    ErrorMessagePipe,
    QuestionComponent,
    QuestionBlockComponent,
    QuestionsAnsweredComponent,
    MatExpansionModule,
    InputFileComponent,
    InputWireTransferEntityComponent,
    WireTransferEditingFormComponent,
    WireTransferEntityDetailsComponent,
    WireTransferSupportiveDocumentationComponent,
    AddWireTransferBankModalComponent,
    TransactionsTableComponent,
    DisplayTransactionsModalComponent,
    StateTimelineComponent,
    WireTransferFormsFilterComponent,
    WireProfileEntityTypePipe,
    InputCurrencyComponent,
    CurrencyFullNamePipe,
    ProcessingRdcsTableComponent,
    BodyDesignComponent,
    ReactiveFormsModule,
    KeysPipe,
    ScannerInfoCardComponent,
    AccountsActivityTableComponent,
    TableComponent,
    ProvideMoreInfoAnswerModalComponent,
    EditQuestionAnswerModalComponent,
    WireTransferBankDetailsComponent,
    ToggleGraphsIconComponent,
    PersonTypePipe,
    PersonNamePipe,
    MasterPersonNamePipe,
    EntityTypePipe,
    TableMessagesPipe,
    AccountDetailsBalance,
    WireTransferEntityAddressComponent
  ]
})
export class SharedModule {
}
