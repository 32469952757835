import {Component, effect, input, Input, OnInit} from '@angular/core';
import {defaultDebounceTime} from '@app/shared-module/utils/utils';
import {toObservable} from '@angular/core/rxjs-interop';
import {debounce} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {timer} from 'rxjs/internal/observable/timer';

@Component({
  selector: 'shared-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit {

  @Input()
  value = 100;

  displayDebouncedWhenLoading = input(false);
  loading = input(false);

  display: Observable<boolean>;

  constructor() {

    const loading$ = toObservable(this.loading);
    effect(() => {
      if (this.displayDebouncedWhenLoading()) {
        this.display = loading$.pipe(debounce(it => it ? timer(defaultDebounceTime) : of(it)));
      } else {
        this.display = of(true);
      }
    });
  }

  ngOnInit() {
  }

}
