import {Injectable} from '@angular/core';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BackendConfiguration} from '@shared/models/non-db/configuration';

@Injectable()
export class ConfigurationState {


  private readConfiguration$ = new BehaviorSubject<boolean>(true);

  private readingConfiguration: Observable<boolean>;
  private configuration: Observable<BackendConfiguration>;

  public constructor(private adminApiService: AdminApiService,
                     private rxUtilsService: RxUtilsService) {

    const startSignal = this.readConfiguration$.asObservable();
    this.configuration = this.rxUtilsService.createGetStateValue(
      startSignal,
      this.adminApiService.getConfiguration(),
      'Error while reading configuration',
      null);
    this.readingConfiguration = this.rxUtilsService.createReadingInfo(startSignal, this.configuration);
  }

  public getConfiguration(): Observable<BackendConfiguration> {
    return this.configuration;
  }

  public getReadingConfiguration() {
    return this.readingConfiguration.pipe(distinctUntilChanged());
  }

}
