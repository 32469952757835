import {Component, Inject, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Alert, AlertsService} from '@app/core-module/services/alerts.service';
import {DebugService} from '@app/core-module/services/debug.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Profile} from '@shared/models/profile';
import {NgbCarouselConfig, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {LanguageService} from '@app/core-module/services/language.service';
import {environment} from '@env/environment';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  public alerts: Alert[] = [];
  public debugAlerts: Alert[] = [];


  constructor(private alertService: AlertsService,
              private debugService: DebugService,
              private carouselConfig: NgbCarouselConfig,
              private tooltopConfig: NgbTooltipConfig,
              private languageService: LanguageService,
              private titleService: Title,
              @Inject(DOCUMENT) private _document: HTMLDocument) {
    this.titleService.setTitle(environment.bankName);
    this._document.getElementById('appFavicon').setAttribute('href', environment.bankIconPath);
    this.alertService.getAlerts().subscribe(alert => {
      this.alerts.push(alert);
      setTimeout(() => {
        this.removeAlert(alert);
      }, 8000);
    });
    this.debugService.getDebugMessages().subscribe(alert => {
      this.debugAlerts.push(alert);
    });

    this.carouselConfig.interval = -1;
    this.tooltopConfig.placement = 'bottom';
    this.tooltopConfig.container = 'body';
    this.languageService.setInitialLanguage();
  }

  ngOnInit() {
  }

  public removeDebugAlert(alert: Alert) {
    const index: number = this.debugAlerts.indexOf(alert);
    this.debugAlerts.splice(index, 1);
  }

  public removeAlert(alert: Alert): void {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }
}
